import axios from "@/plugins/axios";

export default {
    student_by_nic( base_url, params ){
        return axios.get( base_url + '/nic-to-student', { params } )
    },
    student_by_sid( base_url, params ){
        return axios.get( base_url + '/sid-to-student', { params } )
    },
    select(module, params) {
        return axios.get('/api/backend/select/' + module, { params })
    },
    remove_class_from_student(params) {
        return axios.post('/api/backend/remove-class-from-student',  params)
    },
    async post(url, params) {
        return await axios.post(url, params)
    },
    async get(url, params) {
        return await axios.get(url, { params })
    },
    async downloadFile(url, params) {
        return await axios.get(url, {
            params: params,
            responseType: "blob"
        })
    }
}
